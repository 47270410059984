// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1732879639232.8933";
}
// REMIX HMR END

import { Spinner } from "@nextui-org/react";
import { useLoaderData, useNavigate } from "@remix-run/react";
import { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import logo from "~/assets/images/logo.png";
import { getAuthSession } from "~/auth-session";
export default function SplashScreen() {
  _s();
  const navigate = useNavigate();
  const {
    user,
    token
  } = useLoaderData();
  useEffect(() => {
    setTimeout(() => {
      if (token && user) {
        navigate(`/${user.role}`);
      } else {
        navigate("/login");
      }
    }, 2800);
  }, []);
  return <div className='h-screen bg-slate-950 flex flex-col items-center justify-center'>
            <Fade direction='up' duration={600} cascade>
                <img src={logo} alt='company logo' className='w-[20rem]' />
                <h1 className='text-3xl md:text-5xl text-white text-center font-montserrat font-extrabold mb-6'>
                    Med Treatment App
                </h1>
                <Spinner size='lg' />
            </Fade>
        </div>;
}
_s(SplashScreen, "WC1mvmy1XtebkxO2cg4+EHXc838=", false, function () {
  return [useNavigate, useLoaderData];
});
_c = SplashScreen;
export const loader = async ({
  request
}) => {
  const authSession = await getAuthSession(request.headers.get("Cookie"));
  const token = authSession.get("token");
  const user = authSession.get("user");
  console.log({
    token,
    user
  });
  return {
    token,
    user
  };
};
export const meta = () => {
  return [{
    title: "Adamus Med Treatment"
  }, {
    name: "description",
    content: "."
  }, {
    name: "author",
    content: "KwaminaWhyte"
  }, {
    name: "author",
    content: "Codekid"
  }, {
    name: "og:title",
    content: "Adamus Med Treatment"
  }, {
    name: "og:description",
    content: ""
  }, {
    name: "og:image",
    content: "https://res.cloudinary.com/app-deity/image/upload/v1701282976/qfdbysyu0wqeugtcq9wq.jpg"
  }];
};
var _c;
$RefreshReg$(_c, "SplashScreen");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;